(function() {
    'use strict';

    angular.module('aerosApp')
        .component('aflModalCarouselHiddenInput', {
            controller: aflModalCarouselHiddenInputController,
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-list-input/afl-modal-carousel-list-input.html',
            bindings: {
                group: '=',
                editable: '=',
                fieldDescription: '=',
                model: '=',
                mode: '=',
                fieldName: '=',
                required: '=',
                visible: '=',
                fieldChangeEventHandler: '&',
                parent: '='
            }
        });

    function aflModalCarouselHiddenInputController() {
        var $ctrl = this;

        $ctrl.isVisible = function isVisible() {
            return typeof $ctrl.visible !== "undefined" ?  $ctrl.visible : true;
        };

    }
})();
